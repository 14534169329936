<template>
  <vue-seamless-scroll :data="listData" class="seamless-warp">
    <ul class="item">
      <li v-for="item in listData" :key="item.title">
        <span class="title" v-text="item.title"></span>
        <span class="date" v-text="item.date"></span>
      </li>
    </ul>
  </vue-seamless-scroll>
</template>
<style lang="scss" scoped>
.seamless-warp {
  height: 229px;
  overflow: hidden;
  .item {
    li {
      line-height: 30px;
    }
  }
}
</style>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  data() {
    return {
      listData: [
        {
          title: "无缝滚动第一行无缝滚动第一行11111111111111",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第二行无缝滚动第二行22222222222222222",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第三行无缝滚动第三行333333333333333333",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第四行无缝滚动第四行44444444444444444",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第五行无缝滚动第五行555555555555555555555",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第六行无缝滚动第六行666666666666666666666",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第七行无缝滚动第七行7777777777777777777",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第八行无缝滚动第八行8888888888888888888",
          date: "2017-12-16",
        },
        {
          title: "无缝滚动第九行无缝滚动第九行999999999999999999999999",
          date: "2017-12-16",
        },
      ],
    };
  },
  components: {
    //组件
    vueSeamlessScroll,
  },
  computed: {
    classOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
